import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import AboutSection from "../components/home/AboutSection"
import HowWorkSection from "../components/home/HowWorkSection"
import EventSection from "../components/home/EventSection"
import HeroSection from "../components/HeroSection"
import SpecialsSection from "../components/home/SpecialsSection"
import TestimonialsSection from "../components/home/TestimonialsSection"
import MealsSection from "../components/home/MealsSection"
import DecorativeSection from "../components/DecorativeSection"

const IndexPage = () => (
  <Layout page="index">
    <Seo 
      title="Barwy Zdrowia Catering Dietetetyczny Tarnów Zakliczyn Tuchów Brzesko" 
      description="Dieta pudełkowa o określonej liczbie kalorii z dowozem Tarnów, Tuchów, Zakliczyn, Brzesko i okolice"
      keywords={[`Barwy Zdrowia`,`Dieta pudełkowa`, `dieta`, `dowóz`, `katering dietetyczny`, `catering`, `Tarnów`, `Wierzchosławice`, `Zakliczyn`, `Tuchów`, `Brzesko`, ` Dąbrowa Tarnowska`, `Jedzenie`, `zdrowe odżywaianie`, `odchudzanie`, `zdrowie`, `sylwetka`, `fit`]}
      />

    <HeroSection />
    <DecorativeSection />

    <AboutSection />
    <HowWorkSection />
    <EventSection
      title="Darmowa konsultacja"
      subTitle="z dietetykiem przy zamówieniu"
    />
    <SpecialsSection />
    <TestimonialsSection />
    <MealsSection />

  </Layout>
)

export default IndexPage
