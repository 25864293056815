import * as React from "react"
import PropTypes from "prop-types"
import { graphql, Link, useStaticQuery } from "gatsby"
import DecorativeSection from "../DecorativeSection"
import { GatsbyImage } from "gatsby-plugin-image"

const query = graphql`
  {
    diet1: file(name: { eq: "diet1" }) {
      base
      childImageSharp {
        gatsbyImageData(formats: WEBP, placeholder: BLURRED)
      }
    }
    sport: file(name: { eq: "sport" }) {
      base
      childImageSharp {
        gatsbyImageData(formats: WEBP, placeholder: BLURRED)
      }
    }
    dietWege: file(name: { eq: "dieta-wege" }) {
      base
      childImageSharp {
        gatsbyImageData(formats: WEBP, placeholder: BLURRED)
      }
    }
    bezGlutenuLaktozy: file(name: { eq: "bez_glutenu_bez_laktozy" }) {
      base
      childImageSharp {
        gatsbyImageData(formats: WEBP, placeholder: BLURRED)
      }
    }
    niskieIg: file(name: { eq: "niskie_ig" }) {
      base
      childImageSharp {
        gatsbyImageData(formats: WEBP, placeholder: BLURRED)
      }
    }
  }
`

const SpecialsSection = () => {
  const data = useStaticQuery(query)
  return (
    <section id="zestawy" className="specials">
      <div className="container">
        <div className="section-title">
          <h2>
            Wybierz zestaw<span> dla Siebie</span>
          </h2>
          <p>Z naszym cateringiem masz wybór!</p>
        </div>

        <div className="row">
          <div className="col-lg-3">
            <ul className="nav nav-tabs flex-column">
              <li className="nav-item">
                <a
                  className="nav-link active show"
                  data-bs-toggle="tab"
                  href="#tab-1"
                >
                  Optimal
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-bs-toggle="tab" href="#tab-3">
                  Sport
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-bs-toggle="tab" href="#tab-2">
                  Wege
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-bs-toggle="tab" href="#tab-4">
                  Wege + Ryby
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-bs-toggle="tab" href="#tab-5">
                  Bez glutenu
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-bs-toggle="tab" href="#tab-6">
                  Bez laktozy
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-bs-toggle="tab" href="#tab-7">
                  Niski IG
                </a>
              </li>
            </ul>
          </div>
          <div className="col-lg-9 mt-4 mt-lg-0">
            <div className="tab-content">
              <div className="tab-pane active show" id="tab-1">
                <div className="row">
                  <div className="col-lg-7 details order-2 order-lg-1">
                    <h3>Dieta Optimal</h3>
                    <p>
                      Dieta najbardziej elastyczna, stworzona z uwzględnieniem
                      wszystkich grup produktów. Jadłospis jest zbilansowany,
                      różnorodny, smaczny i zdrowy! Świetna opcja dla osób
                      zabieganych! Chcesz zrzucić zbędne kilogramy? Nie wiesz
                      jak poprawić jakość codziennego żywienia? Z tą dietą
                      odczujesz różnice wśród doskonałej jakości produktów i
                      potraw!
                    </p>
                    <br />

                    <div>
                      <Link
                        to="/plan-optimal"
                        data-aos="fade-up"
                        data-aos-easing="ease"
                        className="filter-active btn-see-more animate__animated animate__fadeInUp scrollto"
                      >
                        Zobacz więcej
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-5 text-center order-1 order-lg-2">
                    <GatsbyImage
                      image={data.diet1.childImageSharp.gatsbyImageData}
                      alt="Dieta Optimal"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>

              <div className="tab-pane" id="tab-2">
                <div className="row">
                  <div className="col-lg-7 details order-2 order-lg-1">
                    <h3>Dieta Wege</h3>
                    <p>
                      Jest to dieta wykluczająca każdy rodzaj mięsa. Jadłospis
                      jest zbilansowany, różnorodny i zawiera m.in. takie
                      produkty jak sery, mleko, produkty mleczne, jaja, pestki,
                      nasiona, bakalie, owoce, warzywa, a także szeroką gamę
                      produktów zbożowych. Jeśli jesteś wegetarianinem ten wybór
                      będzie dla Ciebie najlepszy! <br />
                    </p>
                    <div>
                      <Link
                        to="/plan-wege"
                        className="filter-active btn-see-more animate__animated animate__fadeInUp scrollto"
                      >
                        Zobacz więcej
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-5 text-center order-1 order-lg-2">
                    <img
                      src={
                        data.dietWege.childImageSharp.gatsbyImageData.images
                          .fallback.src
                      }
                      alt="Dieta Wege"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>

              <div className="tab-pane" id="tab-3">
                <div className="row">
                  <div className="col-lg-7 details order-2 order-lg-1">
                    <h3>Dieta Sport</h3>
                    <p>
                      Dieta skomponowana z myślą o osobach uprawiających sport
                      zawodowo lub amatorsko. Urozmaicony, pełnowartościowy i
                      kolorowy jadłospis ułatwi życie i wzniesie Cię na wyższy
                      poziom sportowy!
                      <br />
                      Dziś nieodłącznym elementem osiąganych wyników jest także
                      żywienie, które ma ogromny wpływ na odpowiednie
                      funkcjonowanie organizmu.
                      <br />
                      Jeśli zależy Ci zarówno na wynikach, zdrowiu, jak i dobrym
                      samopoczuciu ta dieta jest dla Ciebie!
                      <br />
                    </p>
                    <div>
                      <Link
                        to="/plan-sport"
                        className="filter-active btn-see-more animate__animated animate__fadeInUp scrollto"
                      >
                        Zobacz więcej
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-5 text-center order-1 order-lg-2">
                    <img
                      src={
                        data.sport.childImageSharp.gatsbyImageData.images
                          .fallback.src
                      }
                      alt="Dieta Sport"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>

              <div className="tab-pane" id="tab-4">
                <div className="row">
                  <div className="col-lg-7 details order-2 order-lg-1">
                    <h3>Dieta Wege + Ryby</h3>
                    <p>
                      Dieta, która została stworzona na zasadach diety
                      wegetariańskiej poszerzonej o grupę produktów jakimi są
                      ryby. Jest to cenne źródło wielonienasyconych kwasów
                      tłuszczowych, a także pełnowartościowego białka. Jadłospis
                      jest skomponowany w taki sposób, by sprostać oczekiwaniom
                      naszych najbardziej wymagających klientów!
                      <br />
                    </p>
                    <div>
                      <Link
                        to="/dieta-wege-ryby"
                        className="filter-active btn-see-more animate__animated animate__fadeInUp scrollto"
                      >
                        Zobacz więcej
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-5 text-center order-1 order-lg-2">
                    <img
                      src={
                        data.dietWege.childImageSharp.gatsbyImageData.images
                          .fallback.src
                      }
                      alt="Plan Wege + Ryby"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>

              <div className="tab-pane" id="tab-5">
                <div className="row">
                  <div className="col-lg-7 details order-2 order-lg-1">
                    <h3>Dieta Bez Glutenu</h3>
                    <p>
                      Dieta skierowana dla osób z obniżoną tolerancją na gluten.
                      Używamy w tym jadłospisie m.in. bezglutenowych produktów
                      zbożowych, chude mięso, ryby, nabiał, owoce i warzywa.
                      Produkty zawierające gluten zostały wyeliminowane.
                      <br />
                    </p>
                    <div>
                      <Link
                        to="/dieta-bez-glutenu"
                        className="filter-active btn-see-more animate__animated animate__fadeInUp scrollto"
                      >
                        Zobacz więcej
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-5 text-center order-1 order-lg-2">
                    <img
                      src={
                        data.bezGlutenuLaktozy.childImageSharp.gatsbyImageData.images
                          .fallback.src
                      }
                      alt="Dieta bez Glutenu"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>

              <div className="tab-pane" id="tab-6">
                <div className="row">
                  <div className="col-lg-7 details order-2 order-lg-1">
                    <h3>Dieta Bez Laktozy</h3>
                    <p>
                      Dieta skierowana dla osób zmagających się ze stwierdzoną
                      nietolerancją laktozy lub dla tych, którzy czują się źle
                      po produktach zawierających laktozę i podejrzewają u
                      siebie słabą jej tolerancję. Jadłospis jest skomponowany w
                      taki sposób, by dostarczać wszystkich potrzebnych witamin
                      i minerałów, a jednocześnie jest smaczny i urozmaicony.
                      <br />
                    </p>
                    <div>
                      <Link
                        to="/dieta-bez-laktozy"
                        className="filter-active btn-see-more animate__animated animate__fadeInUp scrollto"
                      >
                        Zobacz więcej
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-5 text-center order-1 order-lg-2">
                    <img
                      src={
                        data.bezGlutenuLaktozy.childImageSharp.gatsbyImageData.images
                          .fallback.src
                      }
                      alt="Dieta bez Laktozy"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>

              <div className="tab-pane" id="tab-7">
                <div className="row">
                  <div className="col-lg-7 details order-2 order-lg-1">
                    <h3>Dieta o Niskim Indeksie Glikemicznym</h3>
                    <p>
                      Jadłospis został stworzony dla osób borykających się z
                      cukrzycą, insulinoopornością, lub tych, którym została
                      zalecona dieta o niskim indeksie glikemicznym.
                      Pełnowartościowa dieta dla tych, którzy mają problem z
                      utrzymaniem prawidłowego poziomu cukru we krwi.
                      <br />
                    </p>
                    <div>
                      <Link
                        to="/dieta-niski-ig"
                        className="filter-active btn-see-more animate__animated animate__fadeInUp scrollto"
                      >
                        Zobacz więcej
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-5 text-center order-1 order-lg-2">
                    <img
                      src={
                        data.niskieIg.childImageSharp.gatsbyImageData.images
                          .fallback.src
                      }
                      alt="Dieta o niskim indeksie glikemicznym."
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SpecialsSection
