import * as React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const query = graphql`
{
  step2: file(name: {eq: "step2"}) {
    base
    publicURL
    childImageSharp {
      gatsbyImageData(placeholder: BLURRED)
    }
  }
  step3: file(name: {eq: "step3"}) {
    base
    publicURL
    childImageSharp {
      gatsbyImageData(placeholder: BLURRED)
    }
  }
  step6: file(name: {eq: "step6"}) {
    base
    publicURL
    childImageSharp {
      gatsbyImageData(placeholder: BLURRED)
    }
  }
  step8: file(name: {eq: "step8"}) {
    base
    publicURL
    childImageSharp {
      gatsbyImageData(placeholder: BLURRED)
    }
  }
}`

const HowWorkSection = () => {
  const data = useStaticQuery(query);
  return (
    <section id="how-works" className="how-works">
      <div className="container">

        <div className="section-title">
          <h2>Jak<span> działamy ?</span></h2>
          <p>Wybierz odpowiedni plan posiłków dla siebie na każdy dzień.</p>
        </div>

        <div className="row">

          <div className="col-lg-3">
            <div className="box">
              <div className="rounded-box">
                <GatsbyImage image={data.step2.childImageSharp.gatsbyImageData} alt="Wybierz dietę" />
              </div>

              <h4>Wybierz dietę</h4>
              <p>Przygotowaliśmy zróżnicowaną ofertę diet aby każdy mógł znaleść odpowiednią dietę dla siebie.</p>
            </div>
          </div>

          <div className="col-lg-3 mt-3 mt-lg-0">
            <div className="box">
              <div className="rounded-box">
                <GatsbyImage image={data.step3.childImageSharp.gatsbyImageData} alt="Złóż zamówienie" />
              </div>

              <h4>Złóż zamówienie</h4>
              <p>Złóż zamówienie telefonicznie bądź online.</p>
            </div>
          </div>

          <div className="col-lg-3 mt-3 mt-lg-0">
            <div className="box">
              <div className="rounded-box">
                <GatsbyImage image={data.step6.childImageSharp.gatsbyImageData} alt="Oczekuj dostawy" />
              </div>
              <h4>Oczekuj dostawy</h4>
              <p>Codziennie otrzymasz dostawę pod drzwi Twojego domu lub biura.</p>
            </div>
          </div>

          <div className="col-lg-3 mt-3 mt-lg-0">
            <div className="box">
              <div className="rounded-box">
                <GatsbyImage image={data.step8.childImageSharp.gatsbyImageData} alt="Delektuj się" />
              </div>
              <h4>Delektuj się</h4>
              <p>Ciesz się zdrowym i pysznym jedzeniem oraz wymarzoną sylwetką!</p>
            </div>
          </div>

        </div>

      </div>
    </section>
  )
}

export default HowWorkSection
