import * as React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import DecorativeSection from "../DecorativeSection";
import { GatsbyImage } from "gatsby-plugin-image";

const query = graphql`
{
  food4: file(name: {eq: "food4"}) {
    base
    childImageSharp {
      gatsbyImageData(
        formats: WEBP
        placeholder: BLURRED
      )
    }
  }

  food5: file(name: {eq: "food5"}) {
    base
    childImageSharp {
      gatsbyImageData(
        formats: WEBP
        placeholder: BLURRED)
    }
  }

  food6: file(name: {eq: "food6"}) {
    base
    childImageSharp {
      gatsbyImageData(
        formats: WEBP
        placeholder: BLURRED)
    }
  }
}
`

const MealsSection = () => {
    const data = useStaticQuery(query);
    return (
        <>
    <section id="chefs" className="chefs">
      <div className="container">

        <div className="section-title">
          <h2>Przykładowe<span> Posiłki</span></h2>
          <p>Specjalnie dla Ciebie przygotowujemy codziennie różnorodne i niepowtarzalne zestawy świeżych posiłków.</p>
        </div>

        <div className="row">

          <div className="col-lg-4 col-md-6">
            <div className="member">
              <div className="pic"><GatsbyImage image={data.food5.childImageSharp.gatsbyImageData} alt={data.food5.base} className="img-fluid meal-img" alt=""/></div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="member">
              <div className="pic"><GatsbyImage image={data.food4.childImageSharp.gatsbyImageData} alt={data.food4.base} className="img-fluid meal-img" alt=""/></div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="member">
              <div className="pic"><GatsbyImage image={data.food6.childImageSharp.gatsbyImageData} alt={data.food6.base} className="img-fluid meal-img" alt=""/></div>
              {/* <div className="member-info">
                <h4>Syty obiad: mięso, ryż, surówka</h4>
              </div> */}
            </div>
          </div>

        </div>

      </div>
    </section>
        </>
    )
}


export default MealsSection
