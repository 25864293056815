import * as React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"

const query = graphql`
{
    file(name: {eq: "bg1"}) {
        childImageSharp {
          gatsbyImageData(formats: WEBP, height: 1080, width: 1920, quality: 25)
        }
      }
}
`
const HeroSection = () => {
    const data = useStaticQuery(query);

    return <section id="hero">
        <div className="hero-container">
            <div className="hero-inner" role="listbox">
                <div className="hero-item active" css={{ backgroundImage: `url(${data.file.childImageSharp.gatsbyImageData.images.fallback.src})` }}>
                    <div className="h-container">
                        <div className="hero-content">
                            <h2 data-aos="zoom-in"><span>Barwy</span> Zdrowia</h2>
                            <p data-aos="fade-up" data-aos-delay="400">Codziennie świeże, zdrowe i smaczne posiłki z dowozem pod wskazany przez Ciebie adres.</p>
                            <div data-aos="fade-up" data-aos-easing="ease" data-aos-delay="800">
                                <a href="#zestawy" className="btn-menu">Wybierz dietę</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
}

export default HeroSection;