import * as React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import DecorativeSection from "../DecorativeSection";

const query = graphql`
{
  file(name: {eq: "img1"}) {
    childImageSharp {
        gatsbyImageData(formats: WEBP, height: 1080, width: 1920, quality: 50)
      } 
  }
}
`

const EventSection = ({ title, subTitle }) => {
    const data = useStaticQuery(query);
    return (
        <>
            <section id="events" className="events" css={{ backgroundImage: `url(${data.file.childImageSharp.gatsbyImageData.images.fallback.src})` }}>
                <div className="container">
                    <div className="section-title">
                        <h2><span>{title} </span> {subTitle}</h2>
                    </div>
                </div>
            </section>
            <DecorativeSection />
        </>
    )
}


export default EventSection
