import React, { useRef, useState } from "react";
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore,{ Scrollbar, Pagination, Autoplay  } from 'swiper';
import "swiper/css";
import "swiper/css/pagination"
import "swiper/css/autoplay"
import 'swiper/css/effect-fade';
import { BsStarFill } from "react-icons/bs";
import DecorativeSectionTop from "../DecorativeSectionTop";

// SwiperCore.use([Pagination]);

const query = graphql`
{
  file(name: {eq: "img4"}) {
    childImageSharp {
      gatsbyImageData(formats: WEBP, height: 1080, width: 1920, quality: 50)
    }
  }
}
`

const TestimonialsSection = () => {
  const data = useStaticQuery(query);
  // const params = new {
  //   speed: 600,
  //   loop: true,
  //   autoplay: {
  //     delay: 5000,
  //     disableOnInteraction: false
  //   },
  //   slidesPerView: 'auto',
  //   pagination: {
  //     el: '.swiper-pagination',
  //     type: 'bullets',
  //     clickable: true
  //   }
  // }
  return (
    <>
      <DecorativeSectionTop />
      <section id="testimonials" className="testimonials" css={{ backgroundImage: `url(${data.file.childImageSharp.gatsbyImageData.images.fallback.src})` }}>
        <div className="container testimonials-container position-relative">
        {/* <Swiper {...params}
          className="testimonials-slider swiper"> */}
          <Swiper
            modules={[Pagination, Scrollbar, Autoplay ]}
            speed={600}
            loop={true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false
            }}
            slidesPerView={"auto"}
            pagination={{ 
              clickable: true,
              type: 'bullets'
            }}
            scrollbar={{ draggable: true }}
            className="testimonials-slider swiper"
            effect="fade">

            <SwiperSlide className="swiper-slide">
              <div className="testimonial-item">
                <h3>Anna</h3>
                <div className="stars">
                  <BsStarFill /><BsStarFill /><BsStarFill /><BsStarFill /><BsStarFill />
                </div>
                <p>
                  <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                  Jedzenie przepyszne!!! Na takiej"diecie" to można być non-stop. Jestem super syta, nie mam zachcianek
                  na słodkie, ponieważ firma uwzględnia zdrowe desery. Ciężko uwierzyć, że mój zestaw pudełek to 1200
                  kcal bo porcję są ogromne. A cena? Policzyłam swoje dzienne zdrowe menu plus ilość czasu na gotowanie
                  i zakupy. Cóż, na jedno wyszło. Będę polecać każdemu!
                  <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                </p>
              </div>
            </SwiperSlide>

            <SwiperSlide className="swiper-slide">
              <div className="testimonial-item">
                <h3>Edyta</h3>
                <div className="stars">
                <BsStarFill /><BsStarFill /><BsStarFill /><BsStarFill /><BsStarFill />
                </div>
                <p>
                  <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                  Próbowałam każdego cateringu dietetycznego w Tarnowie, Barwy Zdrowia mają najlepsze, urozmaicone i
                  pyszne menu, polecam z czystym sumieniem, kreatywność zespołu ogromna!
                  <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                </p>
              </div>
            </SwiperSlide>

            <SwiperSlide className="swiper-slide">
              <div className="testimonial-item">
                <h3>Rafał</h3>
                <div className="stars">
                <BsStarFill /><BsStarFill /><BsStarFill /><BsStarFill /><BsStarFill />
                </div>
                <p>
                  <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                  Polecam. Ze wszystkich Tarnowskich cateringów które testowałem ten jest najlepszy.
                  <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide className="swiper-slide">
              <div className="testimonial-item">
                <h3>Marta</h3>
                <div className="stars">
                  <BsStarFill /><BsStarFill /><BsStarFill /><BsStarFill /><BsStarFill />
                </div>
                <p>
                  <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                  Genialne smaki, których nie powsydziłaby się dobra restauracja! Aż nie moge uwierzyć mam dietę
                  redukcyjną, bo nie mogę się doczekać kolejnego pudełka, ale tym razem nie z głodu, polecam gorąco!
                  <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide className="swiper-slide">
              <div className="testimonial-item">
                <h3>Kama</h3>
                <div className="stars">
                <BsStarFill /><BsStarFill /><BsStarFill /><BsStarFill /><BsStarFill />
                </div>
                <p>
                  <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                  Miałam okazję próbować przez dłuższy okres czasu i teraz będę polecać każdemu, kto szuka miejsca gdzie
                  można dostać pyszne i zdrowe jedzenie.
                  Codziennie świeże i inne, kreatywne posiłki (nie zdarzyło mi się dostać dwa razy tego samego).
                  Dostawa zawsze na czas i przemiła obsługa! oby tak dalej
                  <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                </p>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
    </>
  )
}


export default TestimonialsSection
