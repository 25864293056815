import * as React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import { BsCheckAll } from "react-icons/bs";
// import AOS from 'aos';

const query = graphql`
{
  file(name: {eq: "zdj2"}) {
    childImageSharp {
      gatsbyImageData(formats: WEBP)
    } 
  }
}
`

const AboutSection = () => {
  const data = useStaticQuery(query);

  return (
    <section id="about" className="about">
      <div className="container">

        <div className="section-title">
          <h2>Catering <span>dietetyczny</span></h2>
          <p>Codziennie przygotowujemy kilka pełnowartościowych posiłków, gotowych do spożycia na zimno lub na gorąco.</p>
        </div>

        <div className="row">
          <div className="col-lg-5 align-items-stretch video-box" css={{ backgroundImage: `url(${data.file.childImageSharp.gatsbyImageData.images.fallback.src})` }}>
          </div>
          <div className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch">
            <div className="content">
              <ul 
> 
                {/* <li data-aos="fade-up"  > <strong><BsCheckAll />Robimy to, co kochamy!</strong> */}
                <li data-aos="zoom-in"> <span  ><strong><BsCheckAll/>Robimy to, co kochamy!</strong></span>
                  <p>
                    Zdrowe odżywianie to nasza pasja. Chcemy pokazać, że dieta to nie tylko zbilansowane posiłki ale przede wszystkim smaczna kuchnia!
                  </p>
                </li>
                <li data-aos="zoom-in"> <span ><strong><BsCheckAll /> Zdrowo i świeżo!</strong></span>
                  <p>
                    Stawiamy na zdrowe, świeże a co za tym idzie smaczne posiłki. Składniki wybieramy z szczególną starannością aby zapewnić najwyższą jakość przygotowanych potraw.
                  </p>
                </li>
                <li data-aos="zoom-in"><span><strong><BsCheckAll />Prosto pod drzwi!</strong></span>
                  <p>Dbając o Twój czas dowozimy codziennie 5 świeżych posiłków prosto pod drzwi Twojego domu czy biura.</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutSection
